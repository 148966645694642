import { Pipe, PipeTransform } from '@angular/core';

import { TiaCiPolicyType, TiaManualPolicyType } from './models/tia-aux.model';

@Pipe({
	name: 'tiaFullRunPolicyDescription',
	standalone: true
})
export class TiaFullRunPolicyDescriptionPipe implements PipeTransform {
	transform(value: TiaManualPolicyType | TiaCiPolicyType, ruleMetadata: number | null): string {
		switch (value) {
			case 'daily':
				return 'Every day, on the first build reported after 00:00 (UTC)';
			case 'weekly':
				return 'Every week, on the first build reported after Monday at 00:00 (UTC)';
			case 'monthly':
				return 'Every month, on the first build reported after the 1st at 00:00 (UTC)';
			case 'everyXMonth':
				return `Every ${ruleMetadata} months`;
			case 'everyXTestRuns':
				return `Every ${ruleMetadata} builds (for builds that the selected test stage has run against)`;
			default:
				return null;
		}
	}
}
